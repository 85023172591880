body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

@media (min-width: 1400px) {
  main,
  header,
  #main-navbar,
  #message-input {
    padding-left: 240px;
  }
}

@media (min-width: 1000px) {
  .inner-content {
    padding-left: 240px;
  }
}

ul.navbar-nav.w-100 {
  width: auto !important;
}

.table > thead > tr > th {
  border-bottom-width: 2px;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: inherit;
}

body {
  background-color: #fbfbfb;
}

main {
  margin-top: 58px;
}

.file-upload-wrapper {
  max-width: 300px !important;
}

section.inner-sidenav > div {
  z-index: 999 !important;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.invalid-feedback {
  width: auto;
  color: #f93154;
  margin-top: -2.5rem;
}
